import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import {
  Box,
  ImageLink,
  ButtonRounded,
  Link,
  Typography,
  PRNavbar,
  MobileMenu,
  SearchBar,
} from 'components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { PlayerContext } from 'utils/PlayerContext';
import PRInverted from '../../../../public/logos/PR_inverted.svg';

const Divide = styled.span`
  display: block;
  height: 80%;
  width: 2px;
  background-color: white;
  margin: 0 10px;
`;

const SearchIcon = styled(SVG)`
  height: 100%;
`;

const MenuArrow = styled(SVG)`
  height: 100%;
  width: 15px;
`;

const Logo = styled(SVG)`
  width: 110px;
`;

const variants = {
  open: {
    x: '-100%',
  },
  collapsed: {
    x: '0%',
  },
};

const prVariants = {
  open: {
    x: '100%',
  },
  collapsed: {
    x: '0%',
  },
};

const curtainVariants = {
  open: {
    display: 'block',
    opacity: '0.85',
  },
  collapsed: {
    display: 'none',
    opacity: '0',
  },
};

export const HeaderMobile = () => {
  const { pathname } = useRouter();
  const [open, setOpen] = React.useState<boolean>(false);
  const [prOpen, setPrOpen] = React.useState<boolean>(false);
  const [searchOpen, setSearchOpen] = React.useState<boolean>(false);

  const playerContext = React.useContext(PlayerContext);

  useEffect(() => {
    if (open) setOpen(false);
    if (prOpen) setPrOpen(false);
    if (searchOpen) setSearchOpen(false);
  }, [pathname]);

  const openPlayer = () => {
    playerContext.setPlayerContext({
      type: 'SET_PLAYER',
      payload: {
        isVisible: true,
        isPlaying: true,
        track: null,
        wasStreamPlaying: true,
      },
    });
  };

  return (
    <Box height="60px">
      <Box
        width="100vw"
        position="fixed"
        top={0}
        zIndex={4}
        backgroundColor="neutral.dark"
        display="flex"
        alignItems="center"
        height="60px"
        paddingLeft={3}
      >
        <SVG
          src="/icons/hamburger.svg"
          onClick={() => {
            if (!open) setPrOpen(true);
          }}
        />
        <Box width="30%" height="25%" marginLeft="10px">
          <ImageLink
            href="https://www.polskieradio.pl"
            imgOff={PRInverted}
            alt="Polskie Radio"
          />
        </Box>
        <Box position="absolute" right="-15px">
          <ButtonRounded
            decorator={<SVG src="/icons/play_inverted.svg" />}
            color="main.secondary"
            onClick={openPlayer}
          >
            RADIO
          </ButtonRounded>
        </Box>
      </Box>
      <Box
        width="100vw"
        position="fixed"
        top="60px"
        zIndex={3}
        backgroundColor="main.primary"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="60px"
        px={3}
      >
        <Link href="/" ariaLabel="Jedynka" target="_self">
          <Logo src="/logos/jedynka_inverted.svg" />
        </Link>
        <Box
          height="40%"
          display="flex"
          width="40%"
          alignItems="center"
          justifyContent="flex-end"
        >
          <SearchIcon
            src="/icons/search.svg"
            onClick={() => {
              setSearchOpen(!searchOpen);
            }}
          />
          <Divide />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => {
              if (!prOpen) setOpen(true);
            }}
          >
            <Typography
              color="neutral.light"
              marginRight="15px"
              fontWeight="700"
            >
              MENU
            </Typography>
            <MenuArrow src="/icons/arrow_right.svg" />
          </Box>
        </Box>
      </Box>
      {searchOpen && (
        <Box
          position="fixed"
          top="110px"
          zIndex="4"
          width="100%"
          padding="20px 20px"
        >
          <SearchBar />
        </Box>
      )}
      <motion.div
        initial={false}
        variants={curtainVariants}
        animate={open || prOpen ? 'open' : 'collapsed'}
        style={{ position: 'fixed', top: 0, right: 0, zIndex: 100 }}
      >
        <Box
          width="100vw"
          height="100vh"
          backgroundColor="neutral.dark"
          zIndex={3}
          onClick={() => {
            setOpen(false);
            setPrOpen(false);
          }}
        />
      </motion.div>
      <motion.div
        initial={false}
        variants={variants}
        animate={open ? 'open' : 'collapsed'}
        style={{ position: 'fixed', top: 0, left: '100%', zIndex: 100 }}
      >
        <MobileMenu setOpen={setOpen} open={open} />
      </motion.div>
      <motion.div
        initial={false}
        variants={prVariants}
        animate={prOpen ? 'open' : 'collapsed'}
        style={{ position: 'fixed', top: 0, right: '100%', zIndex: 100 }}
      >
        <PRNavbar setPrOpen={setPrOpen} />
      </motion.div>
    </Box>
  );
};

export const Header = HeaderMobile;
export default Header;
